import {createApp} from 'vue'
import App from './App.vue'
import registrationRouter from './router';
import './assets/scss/design.scss'
import i18n from './i18n'
import routerPlugin from '../plugins/router'
import {emitter} from '@/utils/app';

const app = createApp(App).use(registrationRouter).use(i18n).use(routerPlugin);
app.config.globalProperties.emitter = emitter;
app.mount('#app');

