import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_FlashMessages = _resolveComponent("FlashMessages")!
  const _component_AutoLogout = _resolveComponent("AutoLogout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("main", _hoisted_1, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: this.$route.path
      }))
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_FlashMessages),
    _createVNode(_component_AutoLogout, { ref: "autoLogout" }, null, 512)
  ], 64))
}