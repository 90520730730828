import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showAutoLogoutModal)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onModalHidden: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAutoLogoutModal = false)),
        title: _ctx.$t('autoLogout')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "mt-1 mb-3",
            innerHTML: _ctx.message
          }, null, 8, _hoisted_1),
          _createVNode(_component_FormButton, {
            type: "button",
            label: _ctx.$t('autoLogoutContinue'),
            "data-bs-dismiss": "modal"
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}