
import {setLocaleByRoute} from "@/utils/router";
import 'bootstrap/js/src/collapse.js'
import {createAbortController} from "@/utils/request";
import FlashMessages from "@/components/FlashMessages/FlashMessages.vue";
import Footer from "@/registration/components/Footer.vue";
import Header from "@/registration/components/Header.vue";
import AutoLogout from "@/registration/components/AutoLogout.vue";

export default {
  mounted(): void {
    this.$router.isReady().then(() => {
      setLocaleByRoute();
      document.documentElement.setAttribute('lang', this.$i18n.locale)
      this.$refs.autoLogout.init();
    });
  },
  watch: {
    '$route'(): void {
      createAbortController();
      setLocaleByRoute();
    }
  },
  components: {
    Header,
    Footer,
    FlashMessages,
    AutoLogout,
  },
};
