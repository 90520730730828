
import Modal from "@/components/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import {defineComponent} from "vue";
import {isKA} from "@/utils/app";

export default defineComponent({
  name: 'AutoLogout',
  data(): Record<string, any> {
    return {
      activityTimeoutId: null,
      countDown: 30,
      countDownInterval: null,
      message: '',
      activityTimeoutAmount: 1000 * 90, // 120 sec <= 90 + 30 sec
      showAutoLogoutModal: false,
    }
  },
  mounted() {
    this.emitter.on('update-last-activity', () => {
      this.updateLastActivity();
    });
  },
  methods: {
    init() {
      if (isKA()) {
        window.addEventListener('click', this.updateLastActivity);
        window.addEventListener('scroll', this.updateLastActivity);
        window.addEventListener('keyup', this.updateLastActivity);

        this.updateLastActivity();
      }
    },
    updateLastActivity(): void {
      if (isKA()) {
        clearTimeout(this.activityTimeoutId);
        clearInterval(this.countDownInterval);
        if (this.showAutoLogoutModal) {
          this.showAutoLogoutModal = false;
        }
        this.countDown = 30;

        if (this.$route.name) {
          const routeParts = this.$route.name.split('_');
          if (routeParts.length && routeParts[0] !== 'home') {
            this.activityTimeoutId = setTimeout(() => {
              this.getMessage();
              this.countDownInterval = setInterval(() => this.getMessage(), 1000);
              this.showAutoLogoutModal = true
            }, this.activityTimeoutAmount);
          }
        }
      }
    },
    getMessage(): void {
      this.message = this.$t('autoLogoutInfo').replace('{countdown}', this.countDown);
      if (this.countDown > 0) {
        this.countDown--;
      } else {
        clearInterval(this.countDownInterval);
        clearTimeout(this.activityTimeoutId);
        this.showAutoLogoutModal = false
        this.$redirectToRoute('home');
      }
    }
  },
  components: {
    Modal,
    FormButton,
  },
});
