import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201a6a5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  href: "https://www.ubk.cz/",
  target: "_blank",
  rel: "noopener",
  class: "powered-by"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: { path: _ctx.$getPath('gdpr')},
      class: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('GDPR')), 1)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("a", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('poweredBy')) + " ", 1),
      _createElementVNode("img", {
        src: require('@/assets/img/icons/ubk.svg'),
        class: "powered-by__logo ms-2",
        alt: "UBK s.r.o. logo"
      }, null, 8, _hoisted_3)
    ])
  ]))
}