
import {defineComponent} from 'vue';
import {availableLocales} from "@/registration/i18n";
import {changeRouteByLocale} from "@/utils/router";

export default defineComponent({
  name: 'Header',
  data(): Record<string, any> {
    return {
      availableLocales,
    }
  },
  methods: {
    changeLanguage(langIso: string): void {
      changeRouteByLocale(langIso)
      this.$i18n.locale = langIso;
      document.documentElement.setAttribute('lang', this.$i18n.locale)
    },
  },
});
